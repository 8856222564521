<template>
  <div></div>
</template>
<script>
import { mapMutations } from "vuex";
import { USER, NOTIFY } from "@/request/common";
export default {
  name: "callback",
  data () {
    return {
      code: "",
      openid: ""
    }

  },
  computed: {

  },
  beforeMount () {
    this.wxLogin();
  },
  methods: {
      ...mapMutations(["setMe", 'setNoticeList', 'setToken']),
    wxLogin () {
      var appid = ""
      var uriAll = window.location.href;
      var getindex = uriAll.indexOf(".cn");//获取指定字符串在原字符串条中的位置
      var getstr = uriAll.substring(0, getindex);
      if (getstr.indexOf("test.lanternfish") != -1) {
        appid = 'wx855ff947adfb6ef2'
      } else {
        appid = 'wx6a278bb6f3d73649'
      }
      this.code = this.$route.query.code;
      if (this.code != "") {
        if (this.tool.getCookie("loginUrl") == "/personalCenter/account") {
          this.$axios.post(
            '/admin/social/bind', {
            state: appid,
            code: this.code,
          }
          ).then((data) => {
            if (data.data.code == 0) {
              this.$message({
                message: "授权成功，您可以使用微信二维码扫描登陆！"
              });
              this.$router.push(this.tool.getCookie("loginUrl"))
            }else if(data.data.code == 10002){
              this.$message({
                message: "此手机号与其他微信存在绑定关系，请先去解绑"
              });
              this.$router.push(this.tool.getCookie("loginUrl"))
            }
          })
        } else if (this.tool.getCookie("loginUrl") == "/modidyOrAuth") {
          this.$axios.post(
            '/admin/social/unBind', {
            state: appid,
            code: this.code,
          }
          ).then((data) => {
            if (data.data.data == true) {
              this.$message({
                message: "解绑成功！"
              });
              this.$router.push({ path: this.tool.getCookie("loginUrl"), query: { 'type': 2, 'active': 3 } })
            } else {
              this.$message({
                message: "解绑失败！请联系我们"
              });
              this.$router.push("/personalCenter/account");
            }
          })
        } else {
          const grant_type = 'mobile'
          this.$axios({
            url: '/auth/oauth/token',
            headers: {
              Authorization: "Basic " + this.userSource.token
            },
            method: 'post',
            params: {subject: 'WX@' + this.code, code: this.code, grant_type}
          }).then((data) => {
            if (data.status == 200) {
              if (data.data.phone) {
                this.setToken(data.data.access_token)
                this.$emit("loginDialog", false)
                this.$router.push({ path: this.tool.getCookie("loginUrl"), query: { 'dialogVisible': false } })
                this.getUserInfo();
                this.pageAjax();
              }else{
                // 获取用户信息
                this.setToken(data.data.access_token)
                let user = USER();
                user.then((data) => {
                  this.openid = data.data.wxOpenid;
                  this.tool.setCookie("openId", this.openid);
                  this.tool.setCookie("nickName", data.data.nickname);
                  this.$router.push({ path: this.tool.getCookie("loginUrl"), query: { 'dialogVisible': true, 'wechatLogin': true, 'wechatAuth': true } });
                  setTimeout(() => {
                    window.location.reload([true])
                  },500)
                })
              }
            }else {
              this.$message({
                message: err.response.data.msg
              });
            }
            /*console.log(data)
            if (data.data.code == 1) {
              this.openid = data.data.data.openid;
              this.tool.setCookie("openId", this.openid);
              this.tool.setCookie("nickName", data.data.data.nickName);
              this.$router.push({ path: this.tool.getCookie("loginUrl"), query: { 'dialogVisible': true, 'wechatLogin': true, 'wechatAuth': true } });
              setTimeout(() => {
                window.location.reload([true])
              },500)
            } else {
              if (data.data.access_token) {
                this.setToken(data.data.access_token)
                this.$emit("loginDialog", false)
                this.$router.push({ path: this.tool.getCookie("loginUrl"), query: { 'dialogVisible': false } })
                this.getUserInfo();
                this.pageAjax();
              }
            }*/
          });
        }
      } else {
        this.$message({
          message: "您拒绝了我们的登陆请求！"
        });
        this.$router.push({ path: this.tool.getCookie("loginUrl"), query: { 'dialogVisible': true, 'wechatLogin': true, 'wechatAuth': false } });
      }
    },
    // 获取用户信息
    async getUserInfo () {
      let user = await USER();
      if (user.code == 0) {
        this.setMe(user.data)
      }
      setTimeout(() => {
        window.location.reload([true])
      },500)
    },
    // 获取通知消息
    async pageAjax () {
      let notify = await NOTIFY();
      if (notify.code == 0) {
        this.setNoticeList(notify.data.records)
      }
    }
  }

}
</script>
